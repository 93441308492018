
<template>
    <div>
        <CSDialog
            dialogTitle="提示" dialog-header-class=" "
            dialogWidth="480px" dialog-confirm-btn-text="确定"
            :dialogVisible="deleteOwnerVisible"
            @onClose="deleteOwnerVisible = false"
            @onConfirm="deleteOperate"
            dialog-header-icon="icon-menua-zu13"
        >
            <div
                slot="dialog-content"
                style="padding: 20px 30px; font-size: 24px; text-align: center;"
            >
                确认删除吗?
            </div>
        </CSDialog>
    </div>
</template>
<script>
import { deleteTenantInfoUrl } from "@/requestUrl";
import CSDialog from "@/components/common/CSDialog";

export default {
    props: { notifyLoadDataComponentName: String },
    components: {
        CSDialog,
    },
    data() {
        return {
            deleteOwnerInfo: {},
            deleteTenantId: null,
            deleteOwnerVisible: false,
        };
    },

    mounted() {
        this.$vc.on(this.$route.path, "deleteOwner", "openOwnerModel", (_ownerInfo) => {
            this.deleteOwnerInfo = _ownerInfo;
            this.deleteOwnerVisible = true;
        });
        this.$vc.on(this.$route.path, "deleteTenant", "openOwnerModel", (tenantInfo) => {
            this.deleteTenantId = tenantInfo.id;
            this.deleteOwnerVisible = true;
        });
    },
    methods: {
        closeDeleteOwnerModel: function () {
            this.deleteOwnerVisible = false;
        },
        deleteOperate() {
            if (this.deleteTenantId) {
                this.deleteTenant();
            } else {
                this.deleteOwner();
            }
        },
        deleteOwner: function () {
            this.deleteOwnerInfo.communityId = this.$vc.getCurrentRegion().communityId;
            this.$vc.http.post(
                "deleteOwner",
                "delete",
                JSON.stringify(this.deleteOwnerInfo),
                {
                    emulateJSON: true,
                },
                (json, res) => {
                    //vm.menus = vm.refreshMenuActive(JSON.parse(json),0);
                    if (res.status == 200) {
                        //关闭model
                        this.deleteOwnerVisible = false;
                        this.$vc.emit(this.$route.path,
                            this.notifyLoadDataComponentName,
                            "listOwnerData",
                            {}
                        );
                        return;
                    }
                    this.deleteOwnernfo.errorInfo = json;
                    this.$vc.toast(json);
                },
                (errInfo, error) => {
                    console.log("请求失败处理");

                    this.deleteOwnernfo.errorInfo = errInfo;
                    this.$vc.toast(errInfo);
                }
            );
        },
        deleteTenant() {
            this.$fly
                .post(deleteTenantInfoUrl, {
                    id: this.deleteTenantId,
                    regionId: this.$vc.getCurrentRegion().communityId,
                })
                .then((res) => {
                    if (res.code != 0) {
                        return;
                    }
                    this.closeDeleteOwnerModel();
                    this.$vc.emit(this.$route.path,
                        this.notifyLoadDataComponentName,
                        "listOwnerData",
                        {}
                    );
                });
        },
    },
};
</script>
<style lang="stylus" scoped>
.modal-content
    .modal-title, .modal-body
        font-size 24px
</style>
